import SiteWrapper from "../../../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../../../assets/images/pagerduty_dxable_og.png";

import MdRenderer from "../../../components/mdrender";
import * as md from "../../../components/mdrender.module.sass";
import TitleBlock from "../../../components/title";

import "./easy-scheduling@gen.scss";

const GeneratedProductFeaturesEasyScheduling = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          簡単なスケジューリング - PagerDuty正規代理店 - 株式会社Digital Stacks
        </title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="オンコールスケジュールとエスカレーションによる応答時間の短縮
"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="オンコールスケジュールとエスカレーションによる応答時間の短縮
"
        />

        <meta
          property="og:title"
          content="簡単なスケジューリング - PagerDuty正規代理店 - 株式会社Digital Stacks"
        />

        <meta
          property="og:description"
          content="オンコールスケジュールとエスカレーションによる応答時間の短縮
"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/product/features/easy-scheduling/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/product/features/easy-scheduling/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow="Products"
        textGreen="簡単なスケジューリングと自動エスカレーション"
        overview=""
      />

      <div className="esWrapper">
        <div className="es-container">
          <div className="center-2 first-section">
            <h2 className="center-title">
              オンコールスケジュールとエスカレーションによる
              <br />
              応答時間の短縮
            </h2>
            <p className="center-para">
              オンコールスケジューリングを使用すると、従業員のリソースを計画し重要なアプリケーションやサービスをサポートすることができ、インシデントは適切なタイミングで適切な人に自動的にルーティングされます。PagerDutyは、複雑なローテーションのサポート、簡単なオーバーライドを備えた管理が容易で、導入が容易です。PagerDutyでは自動エスカレーションが簡単です。
              PagerDutyは本当に私たち自身と一緒に動いています
            </p>
          </div>
          {/* detail */}
          <div className="center-1">
            <h2 className="center-title">
              スケジューリングとエスカレーションの詳細
            </h2>
          </div>
          <div className="three-col-without-cta">
            <div className="card">
              <div className="title">
                <p>オンコールタイムライン</p>
              </div>
              <p>
                シンプルなタイムラインで、オンコールまたはオフコールを簡単に確認できます。
              </p>
            </div>
            <div className="card">
              <div className="title">
                <p>
                  オンコールスケジュールを
                  <br />
                  設定する
                </p>
              </div>
              <p>
                標準的なローテーション、週次、平日、週末、まのオンコールスケジュールを設定します。
              </p>
            </div>
            <div className="card">
              <div className="title">
                <p>
                  ローテーションを
                  <br />
                  カスタマイズ
                </p>
              </div>
              <p>
                オンコールスケジュールに、例外ローテーションをカスタマイズ{" "}
                <br />
                します。
              </p>
            </div>
            <div className="card">
              <div className="title">
                <p>リマインド通知</p>
              </div>
              <p className="md:pt-[20px]">
                オンコールのシフトが始まる直前に従業員に自動的にリマインド{" "}
                <br /> します。
              </p>
            </div>
            <div className="card">
              <div className="title">
                <p>簡単なシフトオーバーライド</p>
              </div>
              <p className="md:pt-[20px]">
                オンコールシフトを簡単に変更 <br /> します。
              </p>
            </div>
            <div className="card">
              <div className="title">
                <p>
                  スケジュールを
                  <br />
                  エクスポートします
                </p>
              </div>
              <p className="md:pt-[20px]">
                あなたの会社のカレンダーアプリ(Outlook、Google
                Calendar、iCal)とPagerDutyからのオンコールスケジュールを同期させます。
              </p>
            </div>
            <div className="card">
              <div className="title">
                <p>元のスケジュールに戻す</p>
              </div>
              <p>
                古いスケジュールや変更を表示し、
                ワンクリックで簡単に元のスケジュールに戻すことができます。
              </p>
            </div>
          </div>
          <div className="conclusion">
            PagerDutyのインシデント解決プラットフォームは、ノイズを削減し、
            <br />
            インシデントをより迅速に解決するのに役立ちます。
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedProductFeaturesEasyScheduling;
